<template>
  <div id="app">
    <h2 v-text="currentMonth"></h2>
    <div class="table" v-if="!add">
      <table>
        <thead>
          <tr>
            <th class="day">Den</th>
            <th class="time">Příchod</th>
            <th class="time">Odchod</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(day, i) in daysInMonth" :key="i">
            <td class="day" v-text="day.day"></td>
            <td class="time" v-text="time(day.arrive)"></td>
            <td class="time" v-text="day.leave ? time(day.leave) : '---'"></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="adding" v-if="add">
      <div class="form">
        <label>Den:</label>
        <input type="date" v-model="addDate" />
        <label>Příchod:</label>
        <input type="time" v-model="addArrive" />
        <label>Odchod:</label>
        <input type="time" v-model="addLeave" />
        <button @click="addDay">Přidat</button>
      </div>
    </div>
    <div class="buttons">
      <button @click="previousMonth" class="months">Předchozí</button>
      <button @click="add = !add" :class="{ active: add }">+</button>
      <button @click="nextMonth" class="months">Další</button>
    </div>
    <div class="actions" v-if="!add">
      <div>
        <!--<input type="time" v-model="arriveTime" :disabled="arriveReported">-->
        <button class="arrive" @click="arrive" :disabled="arriveReported">Příchod</button>
      </div>
      <div>
        <!--<input type="time" v-model="leaveTime">-->
        <button class="leave" @click="leave">Odchod</button>
      </div>
    </div>
    <footer>
      <span>{{version}}</span>
    </footer>
  </div>
</template>

<script>
import moment from 'moment';
import axios from "axios";
import { version } from '../package.json';

const apiURL = (input) => { return `https://api.michalwinter.cz/9512${input}`; };

export default {
  name: 'App',
  data: () => ({
    month: 0,
    year: 0,
    data: [],
    add: false,
    addDate: '',
    addArrive: '',
    addLeave: '',
    arriveTime: "",
    manualArrive: false,
    arriveReported: false,
    leaveTime: "",
    version
  }),
  computed: {
    currentMonth() {
      let month = moment().month(this.month - 1).format('MMMM');
      return month.charAt(0).toUpperCase() + month.slice(1) + ' ' + this.year;
    },
    daysInMonth() {
      if (this.data.length === 0) {
        return [];
      }
      else {
        return this.data.filter(day => {
          return moment(day.day, "DD-MM-YYYY").month() === this.month - 1;
        }).sort((a, b) => {
          return moment(a.day, "DD-MM-YYYY").date() - moment(b.day, "DD-MM-YYYY").date();
        });
      }
    }
  },
  created() {
    moment.locale("cs");
    this.month = parseInt(moment().format('M'));
    this.year = parseInt(moment().format('YYYY'));

    /*axios.get(apiURL(`/calendar/${this.year}/${this.month}`))
      .then(response => {
        this.data = response.data;
      })
      .catch(error => {
        console.log(error);
      });*/

    this.getDays();
  },
  methods: {
    time(timestamp) {
      return moment(timestamp).format('HH:mm');
    },
    getDays() {
      axios.get(apiURL("/days"))
      .then(response => {
        this.data = response.data;

        let thisDay = this.data.find(day => {
          return day.day === moment().format('DD-MM-YYYY');
        });
        console.log(thisDay);

        if (!thisDay) this.arriveTime = moment().format('HH:mm');
        else {
          this.arriveTime = thisDay.arrive;
          this.arriveReported = true;

          if (thisDay.leave) this.leaveTime = thisDay.leave;
          else this.leaveTime = moment().format('HH:mm');
        }
      })
      .catch(error => {
        console.log(error);
      });
    },
    previousMonth() {
      if (this.month === 1) {
        this.month = 12;
        this.year--;
      } else {
        this.month--;
      }
    },
    nextMonth() {
      if (this.month === 12) {
        this.month = 1;
        this.year++;
      } else {
        this.month++;
      }
    },
    arrive() {
      let timestamp = moment().toISOString();
      axios.get(apiURL(`/arrive`), {
        params: {
          timestamp
        }
      }).then(response => {
          this.getDays();
        })
        .catch(error => {
          console.log(error);
        });
    },
    leave() {
      let timestamp = moment().toISOString();
      axios.get(apiURL(`/leave`), {
        params: {
          timestamp
        }
      }).then(response => {
          this.getDays();
        })
        .catch(error => {
          console.log(error);
        });
    },
    addDay() {
      let day = moment(this.addDate, "YYYY-MM-DD").format('DD-MM-YYYY');
      let arrive = moment(this.addDate + " " + this.addArrive, "YYYY-MM-DD HH:mm").toISOString();
      let leave = moment(this.addDate + " " + this.addLeave, "YYYY-MM-DD HH:mm").toISOString();

      axios.post(apiURL(`/add`), {
        day,
        arrive,
        leave
      }).then(response => {
          this.getDays();
          this.addDay = "";
          this.addArrive = "";
          this.addLeave = "";
          this.add = false;
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
html, body, #app {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', sans-serif;

  display: flex;
  justify-content: center;

  background: #f7f1e3;
}

#app {
  max-width: 500px;

  padding: 20px;

  display: flex;
  flex-direction: column;
  gap: 15px;

  font-weight: 500;

  h1 {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  .buttons {
    display: flex;
    gap: 10px;

    button {
      all: unset;
      flex-grow: 1;
      padding: 10px 15px;
      border-radius: 5px;

      cursor: pointer;

      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;

      background: #706fd3;
      color: #fff;

      text-align: center;

      &.active {
        background: #ffb142;
      }

      &.months {
        flex-grow: 2;
        flex-basis: 50%;
      }

      &:not(.months) {
        font-size: 1.4rem;
        line-height: 1rem;
      }
    }
  }

  h2 {
    font-size: 1.2rem;
    text-align: center;
  }

  .table {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;

    table {
      thead th {
        background: #d1ccc0;
        overflow: hidden;

        &:first-child {
          border-radius: 5px 0 0 5px;
        }

        &:last-child {
          border-radius: 0 5px 5px 0;
        }
      }

      th, td {
        padding: 10px;

        &.day {
          width: 100px;
          text-align: center;
        }

        &.time {
          width: 70px;
          text-align: center;
        }
      }
      th {
        font-weight: bold;

      }
    }
  }

  .adding {
    flex-grow: 1;

    .form {
      display: flex;
      flex-direction: column;
      gap: 10px;

      input {
        all: unset;
        padding: 5px 10px;
        border-radius: 5px;

        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;

        background: #d1ccc0;
      }
    }
  }

  .actions {
    display: flex;
    gap: 15px;

    width: 100%;

    div {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 10px;

      button {
        all: unset;
        padding: 15px 15px;
        border-radius: 5px;

        cursor: pointer;

        text-align: center;

        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }

      .arrive {
        background: #33d9b2;
      }
      .leave {
        background: #ff793f;
      }
    }
  }

  footer {
    span {
      display: block;
      position: absolute;
      left: 20px;
      bottom: 12px;
      font-size: 0.8rem;
      color: #706fd3;
    }
  }
}
</style>
